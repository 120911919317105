import * as React from "react";
import styled from "styled-components";
import fredHeadshot from "../images/fred_catona.jpeg";
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined"

const bio = () => {
	return(
		<>
			<p>Fred Catona was the Founder and Chief Marketing Strategist for Bulldozer Digital, a full-service advertising and marketing company that specialized in Digital Convergence Marketing. Fred was featured in the Wall Street Journal, USA Today, Philadelphia Inquirer, Entrepreneur Magazine and over 100 TV, radio, magazine and online media outlets.</p>

			<p>He is generally recognized as the father of "Direct Response" radio advertising.</p>

			<h3>BIO</h3>

			<p>After graduating from East Stroudsburg University, Fred taught school for twelve years during which time he became an award-winning teacher and successful coach. During the later part of his teaching career, he started The Taste of Philadelphia which became one of the first companies to ship perishable food products overnight nationally.</p>

			<p>In 1993, Fred founded Radio Direct Response, the first advertising company to focus solely on radio as its advertising medium. Fred developed and pioneered Direct Response Radio, an extremely effective method to generate sales and sales leads. DRR is easily manageable, measurable and cost effective.</p>

			<p>Jay Walker, the Founder of Priceline.com, turned to Fred and Radio Direct Response in 1995 to launch Priceline.com whose valuation grew to $20 billion in just 18 months. RDR has had numerous successful advertising campaigns with other notable companies such as Free Credit Report.com and Disneyonline.</p>

			<p>In addition to his executive responsibilities with his companies, Fred was a frequent guest speaker and a long time active member of Big Brothers of America.</p>

			<h3>NOW</h3>

			<p>Sadly, Fred Catona passed away at age 70 related to injuries sustained in an automobile accident on May 31, 2016. He will be forever loved, cherished, and missed.</p>
		</>
	)
}

const title = "Fred Catona, Radio marketing pioneer, titan, and friend.";
const description = "A tribute site to the great Fred Catona";

const IndexPage = () => {
	return(
		<>
			<Helmet>
          	<title>{title}</title>
				<meta name="description"     content={description} />
				<meta property="og:url"                content={"https://www.fredcatona.com/" + (isBrowser ? window.location.pathname : "")} />
				<meta property="og:type"               content="website" />
				<meta property="og:title"              content={title} />
				<meta property="og:description"        content="Special offer for Mindfulness Daily users" />
				<meta property="og:image"              content={fredHeadshot} />
				<meta property="og:image:width"        content="940" />
				<meta property="og:image:height"        content="788" />

       		</Helmet>
    		<main>
	    		<ContentOuter>
	    			<ContentInner>
			    		<FredImg src={fredHeadshot} />
			    		<h1>Fred Catona</h1>
			    		<h2>Radio marketing pioneer, titan, and friend. He will be forever missed.</h2>
			    		<Bio>{bio()}</Bio>
			    	</ContentInner>
	    		</ContentOuter>
	    		
	    	</main>
    	</>
    )
}

export default IndexPage




const ContentOuter = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

const ContentInner = styled.div`
	width: 80%;
	display: flex;
	flex-direction: column;

`

const Bio = styled.div`
	max-width: calc(100% - 20vw);
`

const FredImg = styled.img`
	max-width: 120px;
`